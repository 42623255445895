.ant-input {
  background-color: transparent;
}
.ant-modal-content {
  padding: 0 !important;
}
.ant-modal-body {
  padding: 16px;
}
.app-container {
  position: relative;
  text-align: center;
  background-image: url("./assets/app-bg.png");
  width: 100vw;
  height: 100vh;
  /* background-color: #0574b8; */
  background: url("./assets/app-bg.png"),
    linear-gradient(180deg, #ffffff 0%, #1da1f2 100%);
  background-size: cover;

  /* padding-top: 254px; */
}
.logo {
  position: absolute;
  left: 0;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  height: 89px;
  margin: 0 10px;
  padding: 8px;
  background: #8a8a8a1a;

  border-radius: 12px;
  color: #fff;
  cursor: pointer;
}
.icon {
  background: #1da1f2;
  border-radius: 12px;
  color: #fff;
  display: flex;

  align-items: center;
  border-radius: 12px;
  height: 48px;
  justify-content: center;
  width: 48px;
}
/* .app-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #1da1f2 100%);
  opacity: 0.6;
} */
.history.assets {
  max-height: 340px;
  overflow: auto;
}

.history-item {
  align-items: center;
  border-bottom: 1px solid rgba(235, 238, 242, 1);
  color: #000;
  display: flex;
  padding: 12px 16px 12px 0;
  text-align: left;
  width: 100%;
}
.history-item .date {
  margin-right: 10px;
  opacity: 0.6;
  width: 80px;
}
.state {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  height: 19px;
  margin-left: 10px;
  text-align: center;
  width: 72px;
}
.state.Failed {
  background: #ff61871a;
  color: #ff6187;
}
.state.Success {
  background: #55b5951a;
  color: #55b595;
}
.type-label {
  align-items: center;
  display: flex;
}
.record-coin {
  display: flex;
  width: 160px;
  font-family: Inter;
  font-size: 14px;
  justify-content: space-between;
  line-height: 17px;
  /* width: 100%; */
}
.record-coin .value {
  margin-left: 8px;
}
.coin-icon {
  border-style: none;
  vertical-align: middle;
  height: 18px;
  margin-right: 4px;
  width: 18px;
}
.record {
  margin-left: auto;
}
.assets .coin .coin-name,
.coin,
.modal-title {
  display: flex;
  align-items: center;
}
.coin {
  justify-content: space-between;
}
.buttons .name {
  color: #141619;
}

.modal-title {
  justify-content: center;
  line-height: 1.5;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px;
}
.balance-detail {
  font-family: Manrope;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 40px;
}
.add-send-target {
  /* background-color: rgba(50, 50, 50, 0.05); */
  align-items: center;
  display: flex;
  font-family: Inter;
  font-size: 14px;
  justify-content: space-between;
  margin-top: 12px;
  width: 309px;
}
.add-button {
  align-items: center;
  background: rgba(50, 50, 50, 0.05);
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  height: 53px;
  justify-content: center;
  width: 144px;
}

.send-item {
  align-items: center;
  display: flex;
  /* margin-bottom: 12px; */
}
.send-modal .value {
  display: flex;
  align-items: center;
}

.send-item .address {
  background: rgba(50, 50, 50, 0.05);
  border: 1px solid #0000;
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.3);
  font-family: Inter;
  /* height: 53px; */
  margin-right: 4px;
  /* width: 218px; */
}
.send-modal .item {
  align-items: center;
  display: flex;
  margin: 25px 0;
}
.send-modal .label {
  width: 66px;
  height: 50px;
  line-height: 50px;
}
.modal-buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.deposit-btn {
  margin: 8px;
  background: rgba(50, 50, 50, 0.05);
  border: none;
  border-radius: 8px;
  color: #323232;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  height: 36px;
  line-height: 15px;
  width: 201px;
}

.send-item .ant-input-number {
  background-color: rgba(50, 50, 50, 0.05);
}

.deposit-btn.copy {
  background: #1da1f2;
  color: #fff;
}
.tips-title {
  text-align: center;
}

.coin-select-item {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 16px;
  border-radius: 8px;
}
.coin-select-item.claim {
  justify-content: space-between;
}
.coin-select-item.selected {
  background-color: #1da1f233;
}

.claim-modal .tips-title {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 40px;
}
.claim-modal .modal-buttons {
  margin-top: 40px;
}
/* .ant-modal-content {
  width: 500px;
} */
.connect-button {
  display: flex;
  position: absolute;
  right: 0;
  padding: 40px 40px 0 0;
  z-index: 20000;
}
.send-item .count {
  width: 100px;
}
.twitter-connecting {
  margin: 20px;
  text-align: center;
}
.coin {
  margin: 12px 0;
}

.twitter-connect-button {
  position: absolute;
  right: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-button .twitter-connect {
  margin-right: 40px;
  align-items: center;
  height: 48px;
  justify-content: center;
  display: flex;
}

.hash {
  display: flex;
  align-items: center;
}
.open-tx-link {
  margin: 4px;
  height: 15px;
  cursor: pointer;
}
