.start-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.left,
.right {
  width: 50%;
  /* height: 100%; */
}

.left {
  background: #1da1f2;
  position: relative;
}
.right {
  padding: 50px;
  display: flex;
  flex-direction: column-reverse;
}
.start-img {
  width: 569px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.start-text {
  font-family: Manrope;
  font-size: 60px;
  font-weight: 600;
  line-height: 82px;
  letter-spacing: 0px;
  text-align: left;
}
.start-text-title {
  font-family: Manrope;
  font-size: 20px;
  /* font-weight: 500; */
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #141619;
}
.go-app-item {
  cursor: pointer;
  margin: 8px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 80px;
  border-radius: 999px;
  background: #1da1f2;
  color: #fff;
}
.right-container {
}

.bg-img {
  z-index: 100;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  margin: 40px;
}
.bottom-item-container {
  display: flex;
  align-items: center;
}
.bottom-item-container img {
  height: 30px;
}
